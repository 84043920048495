import { Amplify, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import config from "./aws-exports";

Amplify.configure(config);

const Loggedin: React.FC = () => {
  const [loggedin, setLoggedin] = useState(false);
  const [member, setMember] = useState(false);
  useEffect(() => {
    async function auth() {
      console.log("Getting session");
      const a = await Auth.currentSession();
      const groups = a.getAccessToken().payload["cognito:groups"];
      if (typeof(groups)!== "undefined"){
      setMember(groups.includes("member"));
      setLoggedin(a.isValid())
      console.log("Member",member)
      }
    }
    auth();
  }, [member]);

  return (
    <div className="container-fluid">
      {loggedin?<></>:(<div>
    {member?<p>Welcome Member</p>:
    <p>
     Your Membership Request has been received. We will confirm your account shortly.
    </p>}
  </div>)}
     
    </div>
  );
};

export default Loggedin;

// async function auth(){
//     console.log("Getting session")
//     return await Auth.currentSession();

// }

// const session=():string=>{
//     const a=auth()
//     return (JSON.stringify(a))
// }



  