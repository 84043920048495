import React from "react";

const Aboutus: React.FC = () => {
  return (
    <div className="container-fluid">
      <div>
        <div style={{ height: "20px" }}></div>
        <p>Please sign this petition which wil go to Welwyn Hatfield District Council and Grant Shapps</p>
        <a href="http://chng.it/DKL5L5Px">Please sign this Petition</a>
        <br/>
        <br/>
        
        
      </div>
    
    </div>
  );
};

export default Aboutus;
