import React from "react";
import { Link } from "react-router-dom";

const Verified: React.FC = () => {
  return (
    <div className="container-fluid">
    
        <div style={{minHeight: "100vh" }}>
        <p>Thank You for confirming your email. Please log in <Link className="SCPlink" to="/members">here</Link></p>
        </div>
      </div>
  );
};

export default Verified;
