import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const Menu: React.FC = () => {
  return (
    <Navbar collapseOnSelect expand="lg" variant="light">
      <Navbar.Brand>
        <Link className="Logo" to="/">
          <img src="images/logo.png" alt="Digswell Conservation Logo" />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/pictures">Pictures</Nav.Link>
          <Nav.Link href="/news">News</Nav.Link>
          {/* <Nav.Link href="/members">Members</Nav.Link> */}
          <Nav.Link href="/contact">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Menu;
