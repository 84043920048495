import React from "react";
import Carousel from "react-bootstrap/Carousel";

const Pictures: React.FC = () => {
  return (
    <div className="container-fluid">
      <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src="./images/photos/DSC03150.JPG"
      alt="First slide"
    />
    
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src="./images/photos/DSC03151.JPG"
      alt="Third slide"
    />

   
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src="./images/photos/DSC03136.JPG"
      alt="Third slide"
    />

    
    </Carousel.Item>
    </Carousel>
    </div>
  );
};

export default Pictures;
