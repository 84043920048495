import { Amplify } from "aws-amplify";
import { Authenticator } from "aws-amplify-react";
import React from "react";
import config from "./aws-exports";
import Loggedin from "./Loggedin";

Amplify.configure(config);

const Members: React.FC = () => {
  const signUpConfig = {
    defaultCountryCode: "44",
    hideAllDefaults: true,
    signUpFields: [
      {
        label: "Email",
        key: "username",
        required: true,
        placeholder: "Email",
        type: "email",
        displayOrder: 1,
      },
      {
        label: "Password",
        key: "password",
        required: true,
        placeholder: "Password",
        type: "password",
        displayOrder: 2,
      },
      {
        label: "Name",
        key: "name",
        required: true,
        placeholder: "",
        type: "text",
        displayOrder: 3,
      },
      {
        label: "Surname",
        key: "family_name",
        required: true,
        placeholder: "",
        type: "text",
        displayOrder: 4,
      },
      {
        label: "Address",
        key: "address",
        required: true,
        placeholder: "",
        type: "text",
        displayOrder: 5,
      },
      {
        label: "Phone Number",
        key: "phone_number",
        required: true,
        placeholder: "",
        type: "text",
        displayOrder: 6,
      },
      {
        label: "Preferred Username",
        key: "preferred_username",
        required: true,
        placeholder: "",
        type: "text",
        displayOrder: 7,
      },
    ],
  };

  return (
    <Authenticator signUpConfig={signUpConfig}>
      <Loggedin />
    </Authenticator>
  );
};

export default Members;
