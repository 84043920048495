import React from "react";

const Home: React.FC = () => {
  const image = "./images/photos/DSC03143sm.JPG";
  return (
    <div
      className="container-fluid"
      style={{
        
        minHeight: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      <div
        className="container-fluid"
        style={{ paddingRight: "10%", paddingLeft: "10%" }}
      >
        <div
          className="container-fluid"
          style={{
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            // backgroundColor: "white",
          }}
        >
          <h3 className="text-center">Digswell Park Conservation</h3>

          <p className="text-justify"><b>Digswell Park Conservation Group has been set up by local residents who love their village and the local area and want to protect its green belt spaces for future generations. The field by the listed viaduct is green belt and has an Article 4 placed on it. We want to ensure that this beautiful space remains the same pastoral view both now and in the future. </b>
</p>
        </div>
          <img src={image} style={{width:'100%'}} alt="Digswell Viaduct"></img>

      </div>
      {/* <img className="img-fluid" src="./images/photos/DSC03143.JPG" alt="background" /> */}
    </div>
  );
};

export default Home;
