/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:fca11d32-b209-4e4d-8aba-e7ef8eb077b6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_dnkFL0of7",
    "aws_user_pools_web_client_id": "4f40a3hl8274qfhevv6228rf45",
    "oauth": {}
};


export default awsmobile;
