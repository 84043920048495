import React from "react";
import { Container } from "react-bootstrap";
import { Router, Link, Switch, Route } from "react-router-dom";
import Menu from "./Menu";
import Home from "./Home";
import Members from "./Members";
import News from "./News";
import Contact from "./Contact";
import NotFound from "./NotFound";
import Pictures from "./Pictures"
import Verified from "./Verified"
import history from "./history";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App: React.FC = () => {
  return (
    <Router history={history}>
      <div className="App">
        <Menu></Menu>
        <Switch>
          <Route path="/index.html" exact component={Home} />
          <Route path="/" exact component={Home} />
          <Route path="/pictures" exact component={Pictures} />
          <Route path="/news" component={News} />
          <Route path="/members" component={Members} />
          <Route path="/contact" component={Contact} />
          <Route path="/verified" component={Verified} />
          <Route component={NotFound} />
        </Switch>
        <Container>
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <Link className="SCPlink" to="/">
            Digswell Park Conservation
          </Link>
        </Container>
      </div>
    </Router>
  );
};

export default App;